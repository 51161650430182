/* VENDOR SCRIPTS */
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { compose, graphql, Query } from "react-apollo";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import _isEmpty from "lodash/isEmpty";
import _toString from "lodash/toString";
import { setConfigLanguage, setConfigPath, setConfigRegion, setSideNavData } from "../redux/actions";
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
/* END VENDOR SCRIPTS */


/* PAGE LEVEL COMPONENTS */
import { Footer } from "@mmc/footer";
import { NavigationHeader } from "@mmc/navigation-header";
import { CookieConsent } from "@mmc/cookie-consent";
import { SideNavigation } from "@mmc/side-navigation";
import { CommonErrorBoundary } from "@mmc/common-error-boundary";
import { StructuredData } from "@mmc/structured-data";
/* END PAGE LEVEL COMPONENTS */


/* PAGE LEVEL TOOLS */
import DataUtils from "@mmc-tools/mmc-data-utilities";
import { ComponentMapper } from "@mmc-tools/component-engine";
import { cleanObject, VEHICLE_CATEGORY_CODES, getNameplateByVehicleName } from '@mmc/common-libs';
import PageQuery from "../graphql/page-query";
/* END PAGE LEVEL TOOLS */

const dataUtils = new DataUtils();
const { handleNestedProp, isEmptyArray } = dataUtils;
const transparentHeaderComponents = ["Standard_Hero_1_0_0", "Header500_1_0_0"];
const removeBreadCrumbsOn = ["Offer_1_0_0", "OfferDetail_1_0_0", "NextGen_VehicleConfigurator_1_0_0", "ModelCalculation_1_0_0", "PaymentCalculator_1_0_0", "VehicleSelector_1_0_0", "VehicleConfigurator_1_0_0"]

import loadable from '@loadable/component';


const CookieList = loadable(() => import(/* webpackChunkName: "CookieList" */ "@mmc/cookie-list"), {
  resolveComponent: (components) => {
    return components.CookieList
  },
  // You can switch between below fallback components, commented out one will show up even with ssr: true (which is by default)
  // fallback: <div>Loading "@mmc/cookie-list" .....</div>
  fallback: <div></div>
})

class Page extends Component {

  componentDidMount() {
    if (window !== undefined && document.cookie !== undefined && window.location !== undefined && window.location.search !== "") {
      const queryParams = location.search.replace('?', '').split('&');
      queryParams.forEach(keyValuePair => {
        if (keyValuePair.includes("utm_")) {
          document.cookie = `${keyValuePair}; SameSite=Strict;`;
        }
      });
    }
  }

  getNavigationData(pageData, appRegion) {
    const navigationData = {
      header: handleNestedProp(pageData, "pageData.ContentPage.header"),
      footer: handleNestedProp(pageData, "pageData.ContentPage.footer")
    };

    if (navigationData.header === " " || !navigationData.header) {
      navigationData.header = null;
    }
    if (navigationData.footer === " " || !navigationData.footer) {
      navigationData.footer = null;
    }

    const components = handleNestedProp(pageData, "pageData.ContentPage.components");

    if (components && components !== " " && navigationData.header) {
      const firstComponent = components[0] || {};
      const secondComponent = components[1] || {};
      const lastComponent = components[components.length-1] || {};
      const imageCheck = !!(handleNestedProp(firstComponent, 'firstComponent.image.image') !== " " && handleNestedProp(firstComponent, 'firstComponent.image.image') !== null && handleNestedProp(firstComponent, 'firstComponent.image.image') !== "");
      const imageBackgroundCheck = !!(handleNestedProp(firstComponent, 'firstComponent.backgroundImage.image') !== " " && handleNestedProp(firstComponent, 'firstComponent.backgroundImage.image') !== null && handleNestedProp(firstComponent, 'firstComponent.backgroundImage.image') !== "");

      navigationData.header.showBreadCrumbs = !(handleNestedProp(pageData, "pageData.ContentPage.hideBreadcrumb"));

      if (lastComponent.resourceType === 'KeyBuyingAction_1_0_0' && lastComponent.links) {
        navigationData.header.quickLinkIcons = lastComponent.links.map(link => {
          return {
            url: link.url,
            icon: link.icon,
          }
        });
      }

      navigationData.header.hasImageAsset = !!(imageCheck || imageBackgroundCheck);
      navigationData.header.hasVideoAsset = !!(handleNestedProp(firstComponent, "firstComponent.video.source") !== " " && handleNestedProp(firstComponent, "firstComponent.video.source") !== null && handleNestedProp(firstComponent, "firstComponent.video.source") !== "");

      if (transparentHeaderComponents.includes(firstComponent.resourceType)) {
        navigationData.header.solidBackground = false;
      }
      if (firstComponent.theme === "dark") {
        navigationData.header.isDarkTheme = true;
        if (firstComponent.resourceType === "Standard_Hero_1_0_0") {
          navigationData.header.animation = true;
        }
        if (firstComponent.resourceType === "Header500_1_0_0") {
          if (navigationData.header.hasImageAsset === false ) {
            navigationData.header.breadcrumbShift = true;
          } else {
            navigationData.header.breadcrumbShift = false;
          }
        }
      }
      if(firstComponent.resourceType === "OfferDetailV2_1_0_0") {
        navigationData.header.animation = true; /* To Make sure offer detail v2 sits on same layer as breadcrumb */
      }
    }

    return navigationData;
  }

  getCookieConsentData(pageData) {
    let cookieConsent = handleNestedProp(pageData, "pageData.ContentPage.cookieConsent");
    const {link}= cookieConsent || {}

    if (!cookieConsent || cookieConsent === " " || cookieConsent.paragraph === "" || link === " " || link.priority === "none" || link.label === "") {
      cookieConsent = null;
    }
    return cookieConsent;
  }

  getComponents(componentsFromData, theme, restProps) {
    const components = componentsFromData.ContentPage.components;

    if (!components) {
      return null;
    }

    const ComponentMap = new ComponentMapper();
    const componentsWithErrorBoundary = ComponentMap.componentMapper(
      components,
      theme,
      restProps
    ).map((component, idx) => (
      <CommonErrorBoundary
        errorMsg={`${components[idx].resourceType} at index ${idx} has caught an error`}
        key={idx}
      >
        <div id={`${components[idx].resourceType}_${idx}`}>{component}</div>
      </CommonErrorBoundary>
    ));

    return componentsWithErrorBoundary;
  }

  getQueryParams({ currentLang, currentPath, appRegion, error = false, component }) {
    const queryParams = {
      market: appRegion,
      lang: currentLang || this.props.routePathing.defaultLang,
      // includestubs: currentPath == "/dev/stubs" ? true : false
      includestubs: false,
      component,
    };

    if (this.props.routePathMapping) {
      queryParams.path = this.props.routePathMapping[
        currentPath
      ];
    }

    if (currentLang) {
      if (currentLang != "favicon.ico") {
        queryParams.path = (queryParams.path) ? queryParams.path : `/${queryParams.market}/${currentLang}/error-pages/404`;
      }
    }

    return queryParams;
  }

  getRegion(region) {
    if (region === "language-masters") {
      return "gb"
    }

    return region
  }

  formatDefaultLang(lang, region) {

    const formatted = `${lang}-${region.toUpperCase()}`

    return formatted
  }

  vehiclePageDataLayer(market, vehicleModel, vehicleEventType, languageSpecificPageSelection) {
    if (
      market.toLowerCase() === 'ca' &&
      typeof window !== 'undefined' &&
      window.dataLayer &&
      window.dataLayer.filter(item => item.event === 'data_layer_ready').length <= 0
    ) {
      // On load datalayer push
      const { currentLang } = this.props;
      const languageSpecificPage = languageSpecificPageSelection && languageSpecificPageSelection[languageSpecificPageSelection.findIndex(x => x.language === 'en')]?.languageSpecificPage;
      const pathnames = languageSpecificPage && languageSpecificPage.split('/');
      const defaultPathName = window?.location?.pathname.split('/');      
      const isHomepage = (!pathnames && defaultPathName && defaultPathName.length <= 2);   
      const onloadDatacollection = {
        event: "data_layer_ready",
        ...(currentLang && { language: currentLang }),
        ...(pathnames && pathnames.length > 0 && pathnames[2] && { content_group: pathnames[2]?.replaceAll('-', ' ') }),
        ...(isHomepage && { content_group: 'homepage' }),
        ...(pathnames && pathnames.length > 0 && pathnames[3] && { content_group2: pathnames[3]?.replaceAll('-', ' ') }),
        ...(pathnames && pathnames.length > 0 && pathnames[4] && { content_group3: pathnames[4]?.replaceAll('-', ' ') }),
        site: 'cws',
      }
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(onloadDatacollection);
      
      //VLP Page datalayer push
      const nameplate = getNameplateByVehicleName(vehicleModel);
      if(vehicleEventType === 'VLP') {
        let dataCollection = {
          event: "vlp_page",
          nameplate
        }
        dataCollection = cleanObject(dataCollection);
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(dataCollection);
      }
      
      //VDP Page datalayer push
      if(vehicleEventType === 'VDP') {
        let dataCollection = {
          event: "vdp_page",
          nameplate,
          vehicle_category_code: VEHICLE_CATEGORY_CODES[nameplate],
        }
        dataCollection = cleanObject(dataCollection);
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(dataCollection);
      }
    }
  }

  // componentDidCatch(error, info) {
  //   // Push user to error page
  //   if (typeof location !== "undefined") {
  //     const { currentLang } = this.props;
  //     const { protocol, host, pathname } = location;
  //     const path = pathname || "/";
  //     const langRoute = path.split("/")[1] === currentLang ? `${currentLang}/` : "";
  //     location.href = `${protocol}//${host}/${langRoute}error`;
  //   }
  // }

  render() {
    const { appRegion, currentLang, dispatch, theme, errorUrl, currentPath, params, ...restProps } = this.props;
    const {
      paymentVehicle,
      paymentYear,
      zipCode,
      url,
      component,
      cf_offer,
      datasetName
    } = this.props.queryParams;

    let GQLpath = currentPath;
    // Trim route params if present for GQL Query
    if (params && params.routeParamOne) {
      GQLpath = GQLpath.charAt(GQLpath.length-1) === '/' ? GQLpath.substr(0, GQLpath.length-2) : GQLpath;
      GQLpath = GQLpath.match(/(.+)\//)[1];
    }

    if (currentLang !== "favicon.ico") {
      const queryOptions = { currentLang, appRegion, component };
      const variables =
        errorUrl !== null
          ? this.getQueryParams({ ...queryOptions, currentPath: errorUrl, error: true })
          : this.getQueryParams({ ...queryOptions, currentPath: GQLpath });
      const configRegion = this.getRegion(appRegion);
      const configLocale = this.formatDefaultLang(currentLang, configRegion);
      if (paymentVehicle && paymentYear) {
        const transformPaymentYear = Array.isArray(paymentYear) ? paymentYear.map(item => Number(item)): [Number(paymentYear)];
        variables.url = url;
        variables.paymentVehicle = paymentVehicle;
        variables.paymentYear = transformPaymentYear;
        variables.zipCode = zipCode;
        variables.paymentModel = '';
      } else if (configRegion === 'ca' && cf_offer && zipCode) {
        variables.zipCode = zipCode;
      }
      if(datasetName){
        variables.datasetName=datasetName
      }

      dispatch(setConfigLanguage(currentLang));
      dispatch(setConfigRegion(configLocale));
      dispatch(setConfigPath(variables.path));

      return (
        <Fragment>
          <Query query={PageQuery} variables={variables} fetchPolicy="cache-first">
            {({ loading, error, data }) => {
              if (loading || !data) {
                return <Fragment />;
              }
              const languageSpecificPageSelection = data.ContentPage.languageSpecificPageSelection;
              const navigationData = this.getNavigationData(data, appRegion);
              const cookieConsent = this.getCookieConsentData(data);
              const useRouteParams = data.ContentPage ? data.ContentPage.useRouteParams : false;
              let sideNavData;
              let feefoSnippetKey;
              let feefoMerchantId
              let searchRootPath;
              let productSkuId;
              let vehicleModel;
              let vehicleEventType;

              if (data.ContentPage && data.ContentPage.components && data.ContentPage.components.length > 0) {
                sideNavData = data.ContentPage.components.filter((comp, idx) => {
                  if (comp.sectionId && comp.sectionTitle) {
                    comp.sectionId = `${comp.sectionId}_${idx}`;
                  }
                  return (comp.sectionId && comp.sectionTitle);
                }).map((comp) => ({ id: comp.sectionId, label: comp.sectionTitle }));

                feefoSnippetKey = data.ContentPage.feefoSnippetKey
                feefoMerchantId = data.ContentPage.merchantInfo
                searchRootPath = data.ContentPage.searchRootPath;
                productSkuId = data.ContentPage.productSkuId;
                vehicleModel = data.ContentPage.vehicleModel;
                vehicleEventType = data.ContentPage.vehicleEventType;
                dispatch(setSideNavData(sideNavData));
              }
              
              return (
                <Fragment>
                  {navigationData.header && <NavigationHeader {...restProps} theme={theme} data={{
                    ...navigationData.header,
                    enableSubNavVLP: data.ContentPage.enableSubNavVLP,
                    enableLocationPrompt: data.ContentPage.enableLocationPrompt,
                    locationPrompt: data.ContentPage.locationPrompt,
                    languageSpecificPageSelection: data.ContentPage.languageSpecificPageSelection,
                    overrideParentPageLabelText: data.ContentPage.overrideParentPageLabelText,
                    pageTitle: data.ContentPage.pageTitle,
                    searchNavigationPath: data.ContentPage.searchNavigationPath
                    }}
                  />}
                  <StructuredData schemaType="Product" data={{...data.ContentPage.jsonLD, productSkuId}}></StructuredData>
                  <StructuredData schemaType="News" data={{...data.ContentPage.jsonLD}}></StructuredData>
                  <StructuredData schemaType="Blog" data={{...data.ContentPage.jsonLD}}></StructuredData>
                  <main role="main">{this.getComponents(data, theme, {
                    path: variables.path,
                    currentPath: this.props.currentPath,
                    feefoSnippetKey,
                    feefoMerchantId,
                    useRouteParams,
                    searchRootPath,
                    ...restProps
                  })}</main>
                  {
                    this.vehiclePageDataLayer(appRegion, vehicleModel, vehicleEventType, languageSpecificPageSelection)
                  }
                  {cookieConsent && <CookieConsent {...restProps} theme={theme} data={cookieConsent} />}
                  {appRegion === 'es' && variables.path.includes('/legal/politica-de-cookies') && (
                    <CookieList {...restProps} theme={theme}/>
                  )}                  
                  <SideNavigation theme={theme} />
                  {navigationData.footer && <Footer {...restProps} theme={theme} data={navigationData.footer} />}
                </Fragment>
              );
            }}
          </Query>
        </Fragment>
      );
    }
      return ("");

  }
}

Page.propTypes = {
  data: PropTypes.object
};

Page.defaultProps = {
  data: {}
};

const mapStateToProps = state => ({
    routePathing: state.routePathing,
    routePathMapping: state.routePathMapping,
    appRegion: state.appRegion,
    requestUrl: state.requestUrl,
    errorUrl: state.errorUrl
  });

export default withRouter(connect(
  mapStateToProps,
  dispatch => ({ dispatch })
)(Page));
